<template>
  <div class="">
    <input :type="inputtype" v-icheck-style="model" :id='Tid' :value='tvalue'
      :checked='model.indexOf(tvalue)>-1'>

    <label :for="Tid">
      {{title}}
    </label>

  </div>
</template>

<script>
  import moment from "moment";
  const listMethods = {

  }
  let nameComputed = {
    tmodel: {
      get() {
        return this.model;
      },
      set(data) {

      }
    }
  }
  export default {
    name: 'CheckBtn',
    data() {
      const tmp = (typeof (this.mod) == "number") ? (this.mod) ? "1" : [] : this.mod;
      let model = (typeof (tmp) == "boolean" || typeof (tmp) == "string") ? [tmp] :
        tmp.map((item) => {
          return (typeof (item) == "number") ? item.toString : item;
        });
      return {
        Tid: this.type + "NO-" + Math.floor(Math.random() * 100000 + 1000000),
        model: model
      };
    },
    watch: {
      model(data) {
        const tmp = typeof (this.mod) != "object" ? [this.mod] : this.mod;
        const check = data.filter((item) => {
          return tmp.indexOf(item) > -1
        })

        if (check.length === data.length && data.length>0) {
          return;
        }

        if (this.type == 'radio') {
          this.$emit("update:mod", data[0] || 0);
        } else {
          this.$emit("update:mod", data);
        }
      },
      mod(data) {
        const tmp = (typeof (data) == "number") ? ((data) ? "1" : []) : data;
        const modal = (typeof (tmp) == "boolean" || typeof (tmp) == "string") ? [tmp] :
          tmp.map((item) => {
            return (typeof (item) == "number") ? item.toString : item;
          });
        this.model.length = 0;
        modal.forEach((item) => {
          this.model.push(item);
        });
        // this.model = (typeof (tmp) == "boolean" || typeof (tmp) == "string") ? [tmp] :
        //   tmp.map((item) => {
        //     return (typeof (item) == "number") ? item.toString : item;
        //   });
        // console.log(this.model);
      }
    },
    methods: listMethods,
    computed: nameComputed,
    props: {
      title: {
        type:String,
        default:""
      },
      tvalue: [String, Boolean, Number],
      mod: [Boolean, Array, String, Number],
      type: {
        type: String,
        default: "checkbox"
      },
      inputtype: {
        type: String,
        default: "checkbox"
      }
    },
  };
</script>