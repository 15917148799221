export default {
  name: 'ComfirmBox',
  methods: {
    hidedefault() {
      this.$emit("update:show", false);
    }
  },
  watch: {
    show(data) {
      if (data) {
        this.Confirm = {
          show: data,
          title: this.title,
          context: this.context
        };
      } else {
        this.Confirm = {
          show: false,
          title: "",
          context: ""
        };
      }
    }
  },
  data() {
    return {
      Confirm: {
        show: false,
        name: "",
        context: ""
      }
    }
  },
  props: {
    show: Boolean,
    title: String,
    context: String,
    okfunc: Function
  },
};
