<template>
  <div>
    <b-modal v-model="Confirm.show" centered size="sm" :header-class="['py-2', 'bg-dagee']"
      :footer-class="['p-0', 'bg-white']" @hide='hidedefault()' @ok='okfunc()'>

      <template v-slot:modal-header='{close}'>
        <b>{{Confirm.title}}</b>
        <div @click="close()">
          <font-awesome-icon :icon="['fas', 'times']" size="lg" />
        </div>
      </template>

      <template v-slot:default>
        <span v-html="Confirm.context"></span>
      </template>

      <template v-slot:modal-footer="{ok, cancel}">
        <div class="d-flex w-100 justify-content-around">
          <span class="text-success" @click="ok">
            <font-awesome-icon :icon="['fas', 'check']" size="lg" />
          </span>
          <span class="text-danger" @click="cancel">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/ComfirmBox";
  export default ctrl;

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

</style>
